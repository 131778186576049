var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm1" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "오더내역" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            !_vm.param.workPlanId && _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "이전 정비오더 불러오기",
                                    icon: "upload_file",
                                  },
                                  on: { btnClicked: _vm.copyData },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.param.workPlanId &&
                            !_vm.disabled &&
                            !_vm.data.woRequestId
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "삭제",
                                    icon: "delete_forever",
                                  },
                                  on: { btnClicked: _vm.removePlan },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            _vm.param.workPlanId
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSaveComplete,
                                    param: _vm.data,
                                    mappingType: "PUT",
                                    label: "작업계획 완료",
                                    icon: "save_alt",
                                  },
                                  on: {
                                    beforeAction: _vm.saveDataComplete,
                                    btnCallback: _vm.saveCompleteCallback,
                                  },
                                })
                              : _vm._e(),
                            _vm.editable && !_vm.disabled
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "정비오더No.",
                              name: "workOrderNo",
                            },
                            model: {
                              value: _vm.data.workOrderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workOrderNo", $$v)
                              },
                              expression: "data.workOrderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "정비오더명",
                              name: "workPlanName",
                            },
                            model: {
                              value: _vm.data.workPlanName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workPlanName", $$v)
                              },
                              expression: "data.workPlanName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              codeGroupCd: "WO_WORK_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "woWorkTypeCd",
                              label: "작업분류",
                            },
                            model: {
                              value: _vm.data.woWorkTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "woWorkTypeCd", $$v)
                              },
                              expression: "data.woWorkTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-equip", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "설비명",
                              name: "equipmentCd",
                            },
                            model: {
                              value: _vm.data.equipmentCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentCd", $$v)
                              },
                              expression: "data.equipmentCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable && !_vm.disabled,
                              data: _vm.data,
                              etcInput: "locationEtc",
                              label: "장소",
                              name: "locationCd",
                            },
                            model: {
                              value: _vm.data.locationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "locationCd", $$v)
                              },
                              expression: "data.locationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "정비오더 작업부서",
                              name: "workDeptCd",
                            },
                            model: {
                              value: _vm.data.workDeptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workDeptCd", $$v)
                              },
                              expression: "data.workDeptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-field", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              data: _vm.data,
                              type: "user",
                              label: "작업계획자",
                              name: "planUserId",
                            },
                            model: {
                              value: _vm.data.planUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "planUserId", $$v)
                              },
                              expression: "data.planUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              range: true,
                              editable: _vm.editable && !_vm.disabled,
                              type: "date",
                              label: "작업계획기간",
                              name: "planDts",
                            },
                            model: {
                              value: _vm.data.planDts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "planDts", $$v)
                              },
                              expression: "data.planDts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable && !_vm.disabled,
                              rows: 8,
                              label: "정비계획 세부사항",
                              name: "remark",
                            },
                            model: {
                              value: _vm.data.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remark", $$v)
                              },
                              expression: "data.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }